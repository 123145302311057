/* .venue-class div {
  cursor: default !important;
}  */

/* .scheduler-class table td {
  height: 142px;
} */

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: "bold";
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.qrcodesection {
  display: flex;
  align-items: center;
}

.grouppass_success_modal {
  width: 600px !important;
}

.success_row {
  justify-content: center;
  align-items: center;
}

.success_txt,
.redeem_txt {
  font-weight: 500 !important;
  padding: 5px !important;
}

.success_txt {
  font-size: 22px !important;
}

.redeem_txt {
  line-height: 30px !important;
  font-weight: bold !important;
}

.fail_text {
  color: #fe0300 !important;
  font-size: 26px !important;
  line-height: 30px !important;
}

.failed_txt {
  line-height: 28px !important;
}

.videodiv > div {
  height: 100% !important;
}

/* .web_invenue_schedule .channel_td > div {
  position: relative !important;
} */

.web_invenue_schedule .channel_td > div p {
  line-height: 22px !important;
  width: auto;
  word-break: break-word;
}

.mob_bio_modal > *:nth-of-type(2) > div > div {
  padding-left: 0px !important;
}

i {
  font-style: italic !important;
}

.overview_bdy {
  padding-top: 40px;
}

.shell_body,
.invenue-page {
  background-color: white !important;
}

.invenue-page #invenue_dropdown,
.invenue-page #invenue_dropdown_channel {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.label_txt {
  font-weight: bold !important;
}

.success_img {
  width: 100%;
  height: 343px;
}

.transabel {
  display: flex;
  flex-flow: row;
  gap: 6px;
  padding-left: 30px;
  padding-bottom: 30px;
  font-size: 13px;
}

label input[type="checkbox"]:checked + label::before {
  background: #02c09e;
}

label path {
  fill: white !important;
}

.hidden {
  display: none !important;
}

.buttons-div {
  display: block;
  display: flex;
  flex-flow: column;
  row-gap: 10px;
}

.paypal-label {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 20px;
}

.paypal-button-number-1 {
  display: none !important;
}

.loader_show {
  position: relative;
}

.loader-pro {
  width: 100%;
  display: none;
}

.nav-tabs {
  padding: 0 9vw !important;
}

.nav-tabs ul li {
  flex: 1;
}

.loader-pro-show {
  width: 100%;
  height: 100%;
  display: block;
  display: flex;
  position: absolute;
  background: white;
}
.loader-product-show {
  width: 100%;
  height: 100%;
  display: block;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: white;
}

.powered_by_section {
  margin-left: 104px;
}

.span_mail {
  cursor: pointer;
  color: #5b5b5b;
  font-size: 14px;
  font-weight: bolder;
  display: block;
}

.moblogo {
  display: none;
  cursor: pointer;
  width: 90%;
}

.baselogo {
  display: none;
  width: 490px;
  cursor: pointer;
  height: 70px;
}

.SSO_header {
  /* height: 100px; */
  background-color: rgb(255, 255, 255) !important;
}

.SSO_header img {
  /* width: 160px;
  height: 53px; */
  /* margin-top: 6px; */
  margin-left: 97px;
  /* width: 200px;
  height: 50px; */
}

.spantag {
  position: relative;
  top: initial;
}

.category-class {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px;
  width: 100%;
}
.category-class button {
  margin: 5px;
  height: 56px;
  border-radius: 10px !important;
  color: #000000;
  /* border-radius: 10px; */
  background: transparent;
  border: 2px solid #999999;
}

.radio_btn_clr div label span {
  color: black !important;
  font-weight: bold;
}

.buy_now {
  width: 70%;
}

.confirm_radio div {
  margin-bottom: 21px;
  margin-left: 20px;
}
.confirm_radio div label {
  font-size: 13px;
  font-family: Rubik;
}

.buy_now_descrip {
  height: 48px;
  font-family: Rubik;
  font-size: 12px;
  color: #626262;
  text-decoration: none solid rgb(98, 98, 98);
}

.alignment {
  text-align: justify;
}

body {
  background-color: white !important;
  overflow: unset !important;
}

.playback-container {
  margin-bottom: 60px;
  padding: 0 9vw !important;
  background-color: white !important;
}

.video-slides {
  box-shadow: none !important;
  border-radius: 5px !important;
  min-height: 0 !important;
  display: flex !important;
  background-color: #ffffff !important;
  flex-direction: column;
}

.contestant-tile {
  width: 33.3%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.vod-tile {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.titletext {
  margin-left: 31px !important;
}
.wrap_pass {
  text-align: left !important;
  margin-bottom: 35px !important;
}
.wrap_para {
  
}
.button-group {
  margin-top: 80px !important;
}
.video_btn {
  width: 50% !important;
}
.wrap_title {
  width: 542px !important;
}
.booktext {
  margin-bottom: 4px !important;
}
a {
  text-decoration: none !important;
}
.channel_td div p {
  margin-bottom: 0px !important;
}
.channel_td div {
  padding: 15px !important;
}
.liveQA {
  font-size: 16px;
  font-weight: bold;
}

.line-in-upcoming {
  width: 132px;
  height: 1px;
  float: left;
  fill: #000000;
  stroke-width: 1;
  display: block;
  margin: 25px 0;
  border-bottom: 1px solid #02c09e;
}

.video-player {
  height: 100% !important;
}

.web_schedule thead,
.web_invenue_schedule thead {
  font-size: 16px !important;
}

.title p {
  font-family: Montserrat;
  font-weight: bold;
}

.title time {
  font-family: Montserrat;
  font-size: 12px;
}

.synopsis {
  font-weight: bold !important;
}

.channel_td div {
  padding: 9px !important;
}

#dropdown,
#dropdown_channel,
#invenue_dropdown_channel {
  border-left: none;
  border-right: none;
  border-top: none;
}

#dropdown input,
#dropdown_channel input,
#invenue_dropdown input,
#invenue_dropdown_channel input {
  color: black;
  height: 100%;
}
.donation-img {
  width: 118px;
  height: 118px;
}

.css-work-shop {
  padding: 0 9vw !important;
}

@media (min-width: 866px) {
  .shell_body {
    padding-right: 105px !important;
    padding-left: 136px !important;
  }
}

@media (min-width: 765px) and (max-width: 866px) {
  .SSO_header img,
  .powered_by_section {
    margin-left: 6px !important;
  }
  .menu_list div {
    padding-right: 45px;
    padding-left: 45px;
  }
  .transaction_box {
    height: auto;
  }
  .coming_soon_div div {
    padding-right: 45px;
    padding-left: 45px;
  }
  .coming-soon {
    padding: 50px 0px 30px 0px;
  }
  .css-workshop {
    padding: 0 5vw !important;
  }
}

.synopissection h1,
.videoDetailFeature h1 {
  font-size: 48px !important;
}

.synopissection h2,
.videoDetailFeature h2 {
  font-size: 39px !important;
}

.synopissection h3,
.videoDetailFeature h3 {
  font-size: 30px !important;
}

.synopissection h4,
.videoDetailFeature h4 {
  font-size: 25px !important;
}

.synopissection h5,
.videoDetailFeature h5 {
  font-size: 18px !important;
}

.synopissection h6,
.videoDetailFeature h6 {
  font-size: 16px !important;
}

.scheduler-class table td div,
.invenue-scheduler-class table td div {
  border-bottom: 2px solid #fff;
}
.pdf-view {
  /* padding: 10px ; */
  margin: 16px 0px 16px 0px;
}
.pdf-view iframe {
  width: 884px !important;
  height: 884px !important;
}
.pdf-view a {
  width: 100% !important;
  margin-top: 20px;
}
.pdf-view .pdf-download {
  align-items: center;
  flex-direction: column;
  display: none !important;
}
.payment-section-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bannerImage {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
}

.bannerImageLap {
  display: none;
}

.featured-writers p {
  margin-bottom: 10px;
}

.featured-writers a {
  color: #02c09e;
  font-weight: bold;
  text-decoration: none;
}

.download-programme-pdf {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.featured-writers {
  padding-top: 50px;
}

.bannerMobile {
  display: none;
}

.carousel {
  min-height: 42vh;
  padding: 0 !important;
  background-color: white !important;
}

.carousel div {
  background-color: white !important;
}

.carousel-slides {
  box-shadow: none !important;
  overflow: visible !important;
  cursor: pointer;
}

.fpp-live-card {
  width: 50% !important;
}

.carousel-slides img {
  width: 100%;
  height: 70%;
}

#dropdown_channel,
#invenue_dropdown_channel {
  display: none;
}

.pass-info {
  background-color: #f0f0f0;
  padding: 20px 40px;
  margin: 50px 0 30px 0;
}

.pass-info p {
  margin: 10px 0 15px 0;
  color: #626262;
  font-size: 14px;
}

.pass-info h6 {
  font-size: 16px;
  font-weight: bold;
}

.pass-info ul {
  list-style-position: inside;
  color: #626262;
}

.pass-info ul li {
  font-size: 14px;
  font-family: Rubik;
  font-weight: 100;
}

.pass-info .sgt {
  margin-bottom: 0;
}

@media (min-width: 320px) and (max-width: 767px) {
  .bannerMobile {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .css-work-shop {
    padding: 0 5vw !important;
  }
  .playback-container {
    padding: 0 10px !important;
  }
}

.addedBorder {
  width: 60%;
  padding-top: 20px;
  border-bottom: 1px solid #d4d4d4;
}
@media (min-width: 320px) and (max-width: 765px) {
  .bannerImage {
    margin-top: 40px;
  }
  .bannerImage img {
    margin-top: 22px;
  }
  .addedBorder {
    width: 100%;
  }
  .appFooter {
    height: 80px !important;
  }
  .contestant-tile {
    width: 50%;
  }
}
.drop-in-payment {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.dropdwon-scheduler {
  width: 160px;
  border: none;
  margin-left: 10px;
  height: 26px;
  padding: 0px;
  border-bottom: 1px solid #8c8c8c;
  background-color: rgb(255, 255, 248);
}
.channel-select {
  width: 160px;
  border: none;
  margin-left: 10px;
  height: 26px;
  padding: 0px;
  border-bottom: 1px solid #8c8c8c;
  background-color: rgb(255, 255, 248);
  display: none;
}
.schedule-title svg {
  margin-left: 10px;
  fill: lightgrey;
}
.main_image {
  width: 209px;
  height: 209px;
}

.pass_head {
  width: auto;
  line-height: 32px !important;
  font-size: 24px !important;
  font-weight: bold !important;
}

.pass_head_para,
.transaction_head_para {
  font-size: 13px !important;
  line-height: 18px !important;
  margin-top: 14px !important;
  margin-bottom: 0px !important;
}

.transaction_head_para {
  padding: 20px;
  color: #000000 !important;
}

.transaction_box {
  background-color: #f9f9f9;
  height: auto;
}

.product_section {
  width: 100%;
  justify-content: space-between;
  flex-direction: row !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.info_section {
  width: 100%;
  display: inline-block;
  font-size: 11px;
  line-height: 17px;
  padding-top: 5px;
}

.confirm_section {
  width: 100%;
  display: inline-block;
  font-weight: 100 !important;
  line-height: 17px;
  padding-top: 7px;
}

.transaction_header {
  width: auto;
}
.now-playing-class {
  background: transparent;
  padding: 0 !important;
}
.now-playing-class svg {
  width: 18px;
  height: 18px;
  padding: 0px;
  position: relative;
  top: 5px;
}

.vod-class span {
  font-size: 15px !important;
}

.card-slides {
  box-shadow: none !important;
  max-width: 380px;
}
.card-slides img {
  display: block;
  width: 380px;
  height: 213px;
  margin-bottom: 10px;
  cursor: pointer;
}
.video-slides {
  box-shadow: none !important;
  margin: 0px !important;
}
.video-slides img {
  height: auto;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.title-tiles {
  font-size: 16px;
  color: #000;
  -webkit-text-decoration: #000;
  text-decoration: #000;
  line-height: 22px;
  display: block;
  font-weight: 700;
  text-overflow: ellipsis;
  cursor: pointer;
  padding-bottom: 10px;
}

.title-tiles-channel {
  font-size: 16px;
  color: #000000;
  text-decoration: rgb(0, 0, 0);
  line-height: 22px;
  display: block;
}

.container {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  padding-top: 40px;
}

.synopiscontainer {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
}

.bookcontainer {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
}

.wrapper {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  /* z-index: 9; */
  color: white;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  background-color: #00254f;
}

.videoDetailFeature {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
  padding: 20px;
}

.videoDetailFeature > h6 {
  text-transform: capitalize !important;
  font-size: 16px !important;
  /* line-height: 2px !important;
  font-weight: 500; */
}

.videoDetailFeature > p {
  margin-bottom: 10px !important;
}

/* .videoDetailFeature > div > div > div {
  flex-direction: column !important;
} */

.row {
  width: 100%;
  display: inline-block;
  position: relative;
  background-color: #fffef7;
}

.row::before {
  content: "";
  position: absolute;
  top: 0;
  background: #00254f;
  left: 0;
  width: 100%;
  height: 500px;
}

.wholediv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.synopisdiv {
  display: flex;
  width: 100%;
}

.bookdiv {
  display: flex;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
}

.titletext {
  line-height: normal !important;
  margin-left: 10px;
  font-size: 18px !important;
  color: white !important;
}

.videodiv {
  width: 70%;
  height: 515px;
}

.mobchatalt {
  height: 420px;
  background-color: white;
  display: none;
}

/* .videodiv > div > div > video {
  height: auto !important;
} */

.text_div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 9;
  color: white;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  background-color: #151515de;
  padding-right: 330px;
  padding-bottom: 0px;
}

.text_div.disablechat {
  padding-right: 0px;
  padding-bottom: 0px;
}

.audience-header-back {
  visibility: hidden !important;
}

.disablechat {
  width: 100%;
}

.chatsection {
  width: 30%;
}

.synopissection {
  width: 70%;
  padding: 20px;
}

.booksection {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 10px;
  /* justify-content: space-between; */
}

.booksection p {
  margin: 0;
}

.bookstore-img {
  width: 118px;
  height: 118px;
}

.booktext {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
}

.spread_section {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  text-decoration: none;
  color: black;
}

.wrap_div {
  padding: 12vh 50px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.96;
  z-index: 9;
  color: white;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  row-gap: 50px;
  line-height: 25px;
  /* background-color: #00326b; */
}
.chatQAMenu {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border-bottom: 1px solid;
  padding: 20px 0px 10px 0px;
}
.chatMenuTitle {
  width: 50%;
  text-align: center;
}
.chatMenuTitleSpan {
  cursor: pointer;
  height: 25px;
  padding-bottom: 7px;
  padding-right: 40px;
  padding-left: 10px;
}
.chatMenuTitle:hover .chatMenuTitleSpan {
  border-bottom: 6px solid #02c09e;
}
.chatMenuTitleImage {
  margin-left: -35px;
  margin-bottom: -5px;
}
.QAimage {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 50px;
  text-align: center;
}
.chatQAMenuActive {
  border-bottom: 6px solid #02c09e;
}

.wrap_title {
  color: white !important;
  width: 500px;
  text-align: left;
  line-height: 33px !important;
  font-size: 28px !important;
}

.wrap_pass {
  color: white !important;
  margin-bottom: 10px;
  width: 542px;
}

.group_pass_head {
  width: auto;
  line-height: 32px !important;
  font-size: 21px !important;
  font-weight: 500 !important;
}

.login_btn {
  width: 288px;
  height: 46px !important;
}

.button_row {
  width: 100%;
}

.group_pass_login_section {
  line-height: 30px !important;
}

.web_schedule,
.web_invenue_schedule {
  visibility: visible;
}

.mob_invenue_schedule {
  display: none;
}

.voddiv > div > div > div > iframe {
  width: 100% !important;
  height: 100% !important;
}

.appFooter {
  background-color: #ffffff !important;
}

.swf-header {
  height: auto !important;
}

.coming-soon {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 5%;
}

.stage_cont {
  padding-top: 10px !important;
  padding-bottom: 40px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.stage_cont p {
  margin: 0 !important;
}

.stage_btn {
  background-color: #02c09e !important;
  color: white !important;
}

.border_bottom {
  padding: 0px !important;
  border: none !important;
}

.upcoming-title {
  font-weight: bold !important;
  color: #828282 !important;
}

.card-filter {
  width: 20vw;
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .category-class {
    display: contents !important;
  }
  .vod-row {
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .coming-soon img {
    width: 674px;
  }
  .mob_schedule th:first-child {
    width: 22% !important;
  }
}

@media (max-width: 1367px) {
  .tab_whole #invenue_dropdown_channel {
    display: flex;
  }
  .web_schedule,
  .web_invenue_schedule {
    display: none;
  }

  .mob_invenue_schedule {
    display: table;
  }

  .mob_invenue_schedule > thead {
    visibility: hidden;
  }
}

@media (min-width: 768px) {
  .playback-container {
    padding: 0 135px !important;
  }
  .bio_modal {
    padding-top: 23px;
    padding-left: 37px;
    padding-right: 47px;
    padding-bottom: 23px;
  }
  .stage_cont h5 {
    font-size: 24px !important;
    font-weight: bold !important;
    line-height: 26px !important;
  }
  .user_buypass,
  .baseuserpass {
    margin-right: 148px;
  }
  .main_image {
    width: 100%;
    height: auto;
  }
  .pass_head {
    width: auto;
    line-height: 32px;
  }
  .product_section {
    width: 100%;
    justify-content: space-between;
  }

  .info_section {
    font-size: 12px;
  }

  .drop-in-payment {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }
  .success_section {
    padding: "0px 0px 40px 40px";
  }

  .price_section_web {
    display: block;
    padding-left: 320px;
  }

  .price_section_mob {
    display: none;
    padding-left: 320px;
  }

  .bookcontainer {
    display: none;
  }

  .bookwebsection {
    display: block;
  }

  .bookmobsection {
    display: none;
  }

  .text_div {
    font-size: 32px;
    line-height: 42px;
  }

  .mike_img {
    width: 728px;
    height: 90px;
    display: block;
  }

  .mike_mob_img {
    display: none;
  }

  .live-grid {
    width: 1200px !important;
    margin: 0 auto;
  }

  .live-bdy {
    padding: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 866px) {
  .coming-soon img {
    width: 100%;
  }
  .playback-container {
    padding: 0 35px !important;
  }
}

@media only screen and (max-width: 767px) {
  .stage_cont h5 {
    font-size: 16px !important;
  }
  .coming-soon img {
    width: 100%;
    height: 100%;
  }
  .success_img {
    height: auto;
  }
  .moblogo {
    display: block;
    padding-top: 5px;
    padding-left: 0px;
  }
  .SSO_header {
    width: 100% !important;
  }
  .SSO_header img {
    /* margin-top: 6px; */
    margin-left: 0px;
    /* width: 145px; */
  }
  .pdf-view .pdf-download {
    display: flex !important;
  }
  .pdf-view iframe {
    display: none !important;
  }
  .group_pass_head {
    width: auto;
    font-size: 18px !important;
    line-height: 30px !important;
    padding-bottom: 24px;
  }
  .group_pass_login_section {
    line-height: 23px !important;
    font-size: 15px !important;
  }
  .button_row {
    padding-top: 20px;
  }
  .payment-section-text {
    display: block;
  }
  .payment-section-text h3 {
    font-size: 18px;
    color: #000000;
    text-decoration: rgb(0, 0, 0);
  }
  .braintree-sheet {
    width: 95%;
  }
  .css-xq2s6o {
    width: 95%;
  }
  .drop-in-payment {
    padding: 15px;
  }
  .try_again_btn {
    width: 100%;
  }

  .transaction_header {
    font-size: 20px !important;
    line-height: 27px !important;
  }

  .price_section_web {
    display: none;
    padding-left: 320px;
  }

  .price_section_mob {
    display: block;
    padding-left: 320px;
  }

  .failed_txt {
    font-size: 14px !important;
  }

  .confirm_section {
    line-height: 21px !important;
    padding-top: 20px !important;
  }

  .row {
    margin-top: 80px;
  }

  .row::before {
    background: black;
    height: 250px;
  }

  .container {
    width: 100%;
    padding-top: 0px;
  }

  .synopiscontainer {
    width: 100%;
  }

  .bookcontainer {
    width: 100%;
    display: block;
  }

  .title {
    display: flex;
    align-items: center;
    padding-top: 26px;
    padding-left: 17px;
    padding-bottom: 15px;
  }

  .wholediv {
    flex-direction: column;
  }

  .synopisdiv {
    flex-direction: column;
  }

  .bookdiv {
    flex-direction: column;
  }

  .videodiv {
    width: 100%;
    height: auto;
    min-height: 150px;
    position: relative;
  }

  .text_div {
    padding-right: 0px;
    padding-bottom: 465px;
  }

  .text_div.disablechat {
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .synopissection {
    width: 100%;
    padding: 20px;
  }

  .chatsection {
    width: 100%;
  }

  .bookwebsection {
    display: none;
  }

  .bookmobsection {
    display: block;
  }

  .wrap_div {
    padding: 30px 25px 0 25px;
  }

  .wrap_title {
    width: 100%;
    line-height: 28px !important;
    font-size: 20px !important;
  }

  .wrap_pass {
    font-size: 17px !important;
  }

  .mobchatalt {
    display: block;
  }

  .login_btn {
    width: 100%;
  }

  .mike_mob_img {
    width: 350px;
    height: 50px;
    display: block;
  }

  .mike_img {
    display: none;
  }

  .channel-select {
    display: block;
  }

  .card-slides {
    padding: 0px !important;
  }
  .video-slides {
    width: 100% !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .mob_invenue_schedule .channel_td {
    width: 100% !important;
  }
}

@media only screen and (max-width: 380px) {
  .card-slides img,
  .video-slides img {
    width: 100%;
  }
}

@media (min-width: 765px) and (max-width: 805px) {
  .baselogo {
    width: 300px;
    height: 50px;
  }
}
@media (min-width: 805px) and (max-width: 905px) {
  .baselogo {
    width: 320px;
    height: 58px;
  }
}
@media (min-width: 767px) {
  .baselogo {
    display: block;
  }

  .bannerImageLap {
    display: block;
    width: 728px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 650px) and (max-width: 767px) {
  .moblogo {
    width: 75%;
  }
}

@media (min-width: 765px) and (max-width: 997px) {
  .shell_body {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .stage_cont {
    padding-right: 0px !important;
  }
  .bannerImage {
    margin-top: 30px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .bannerImageLap {
    width: 100%;
  }
  .footer_div {
    width: 100% !important;
  }
  .user_buypass {
    margin-right: 45px;
  }
}

@media (max-width: 575px) {
  .bio_modal {
    width: 100% !important;
  }
  .css-carousel {
    padding-top: 0px;
  }
  .contestant-tile {
    width: 100%;
  }
  .playback-container {
    padding: 175px 0 0 0 !important;
  }
  .css-watch-free {
    padding: 152px 9vw 0 9vw !important;
    margin-top: 0px;
  }
  .vodContainer {
    padding-top: 7px;
  }
  .title {
    padding: 15px;
    /* padding-top: 18px;
    padding-bottom: 0px; */
  }
  .wholediv {
    height: auto;
  }
  .vod-title {
    margin-left: 21px;
    text-align: left;
  }
  .wrap_div {
    grid-row-gap: 20px !important;
    position: relative !important;
    padding: 9vw;
    flex-direction: column;
  }
  .coming-soon-mob {
    margin-bottom: 67px !important;
  }
  .trailerDiv {
    height: 29.78vh;
    width: 93.5vw;
  }
  .pop_over_content {
    display: block !important;
    height: 280px;
  }
  .digital-booklet-link {
    width: 100%;
  }
  .digital-booklet-link button {
    width: 100%;
  }

  .no-nav {
    margin-bottom: 10px !important;
  }

  .appFooter {
    padding: 0 25px;
  }

  .scheduler-class,
  .invenue-scheduler-class {
    width: 88% !important;
  }
  #dropdown_123,
  #invenue_dropdown_123 {
    display: block !important;
  }
  .schedule-title {
    width: 100% !important;
  }
  #dropdown_channel,
  #invenue_dropdown_channel {
    display: flex !important;
  }
  .carousel {
    margin-top: 60px !important ;
    padding: 0px !important;
  }
  .carousel-slides {
    flex: 1;
  }
  .footer_text {
    font-size: 12px !important;
  }
  .footer_div {
    padding: 5px !important;
  }
  .wrap_title {
    width: auto !important;
  }
  .buy_now_button {
    width: 83vw !important;
  }
  .row {
    margin-top: 0px;
  }
  .row::before {
    background: #0a254f !important;
  }
  .wrap_div {
    opacity: 1 !important;
    /* background: #0a254f !important; */
  }
  .nav-tabs {
    padding: 0 2vw !important;
    font-family: Rubik;
    font-size: 12px;
    text-decoration: none solid rgb(0, 0, 0);
    font-weight: normal;
  }
  .bannerImage {
    width: 94% !important;
  }
  .bannerMobile {
    width: 96% !important;
  }
}

@media (min-width: 360px) and (max-width: 576px) {
  .sticky-header {
    position: sticky !important;
    top: 0;
  }
  .SSO_header {
    position: relative !important;
    top: 0;
    right: 0;
    width: 100%;
    height: auto !important;
  }
  .buy_now {
    width: 100%;
  }
  .powered_by_tab {
    position: relative !important;
    margin-top: 0 !important;
    height: auto !important;
  }
  .stage_img {
    padding: 19px !important;
  }
  .stage_cont {
    padding: 4px 19px 40px 19px !important;
  }
  .stage_cont div {
    padding: 10px 0px 10px 0px !important;
  }
  .stage_btn {
    width: 100%;
  }
  .hr_div {
    margin-left: 19px;
    margin-right: 19px;
  }
  .director_address {
    padding: 10px 19px 10px 19px;
  }
  .director_address div {
    padding: 10px 0px 10px 0px;
  }
  .video_div {
    width: 90% !important;
    margin: 0px 19px 0px 19px !important;
  }
  .bannerImage {
    padding: 20px 0px 10px 0px;
    margin: 0px 19px 0px 19px !important;
  }
  .bannerMobile {
    margin: 0px !important;
  }
  .featured-writers {
    padding: 50px 19px 0px 19px;
  }
  .featured-writers div {
    padding-left: 0px;
    padding-right: 0px;
  }
  .download-programme-pdf {
    padding-left: 19px;
    padding-right: 19px;
  }
  .download-programme-pdf a {
    width: 100% !important;
  }
}

@media (min-width: 575px) and (max-width: 1367px) {
  .contestant-tile {
    width: 50%;
  }
  .video-slides {
    width: 100% !important;
    margin: 0 10px;
  }

  .mob_invenue_schedule .channel_td {
    width: 100% !important;
  }

  .container {
    width: auto !important;
    padding: 5vw;
  }

  .synopiscontainer {
    width: auto !important;
    padding: 2vh 9vw;
  }

  .synopissection {
    width: 100%;
  }

  .synopisdiv {
    flex-direction: column;
  }

  .chatsection {
    width: 100%;
  }

  .wrap_div,
  .wrap_age_div,
  .wrap_explore_div {
    row-gap: 0px !important;
    grid-row-gap: 20px !important;
    position: relative !important;
    padding: 5vw;
  }

  .chatsection {
    margin: 20px 0;
  }

  #dropdown_channel {
    display: flex;
  }
}
@media (max-width: 425px) {
  .popovercontent {
    display: none;
  }
  /* .popover_passcard,
  .popover_success_passcard {
    height: 200px;
  } */
}

@media (max-width: 359px) {
  .playback-container {
    padding: 50px 0 0 0 !important;
  }
  .title-tiles {
    width: 300px !important;
  }
  .scheduler-class {
    margin-top: 0px !important;
  }
  .invenue-scheduler-class {
    margin-top: 20px !important;
  }
}

@media (max-width: 823px) {
  .videodiv {
    width: 100%;
    height: auto;
    min-height: 150px;
    position: relative;
  }
}
